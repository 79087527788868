
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import PageRequest from "../../../store/entities/page-request";
import EditGrupo from "./edit-grupo.vue";
import PeopleFind from "../../../components/People/people-find.vue";

class PageGrupoRequest extends PageRequest {
  nombre: string;
  descripcion: string;
}

@Component({
  components: {
    EditGrupo,
    PeopleFind,
  },
})
export default class Grupo extends AbpBase {
  //filters

  pagerequest: PageGrupoRequest = new PageGrupoRequest();
  creationTime: Date[] = [];
  editCreateModalShow: boolean = false;
  isEditing: boolean = false;

  get list() {
    return this.$store.state.grupo.list;
  }
  get loading() {
    return this.$store.state.grupo.loading;
  }
  async create() {
    this.$store.commit("grupo/cleanCampos");
    this.editCreateModalShow = true;
    this.isEditing = false;
    this.pagerequest = new PageGrupoRequest();
  }

  editRow(item, index, button) {
    this.$store.commit("grupo/edit", item);
    this.edit();
  }
  
  deleteRow(item, index, button) {
    this.$bvModal
      .msgBoxConfirm(this.L("¿Desea eliminar este grupo?"), {
        //title: this.L('Tips'),
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: this.L("Sí"),
        cancelTitle: this.L("No"),
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value) {
          await this.$store.dispatch({
            type: "grupo/delete",
            data: item,
          });
          await this.getpage();
        }
      });
  }
  edit() {
    this.editCreateModalShow = true;
    this.isEditing = true;
  }

  pageChange(page: number) {
    this.$store.commit("grupo/setCurrentPage", page);
    this.getpage();
  }
  pagesizeChange(pagesize: number) {
    this.$store.commit("grupo/setPageSize", pagesize);
    this.getpage();
  }

  async getpage() {
    this.pagerequest.maxResultCount = this.pageSize;
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

    await this.$store.dispatch({
      type: "grupo/getAll",
      data: this.pagerequest,
    });

    const pagination = { ...this.pagination };
    pagination.total = this.$store.state.grupo.totalCount;
    pagination.pageSize = this.$store.state.grupo.pageSize;
    pagination.current = this.$store.state.grupo.currentPage;
    this.pagination = pagination;
  }
  get pageSize() {
    return this.$store.state.grupo.pageSize;
  }
  get totalCount() {
    return this.$store.state.grupo.totalCount;
  }
  get currentPage() {
    return this.$store.state.grupo.currentPage;
  }
  set currentPage(page) {
    this.$store.commit("grupo/setCurrentPage", page);
    this.getpage();
  }

  async created() {
    this.getpage();
  }

  columns = [
    {
      title: this.L("Nombre"),
      dataIndex: "nombre",
      thClass: "w-auto",
      tdClass: "w-auto",
    },
    {
      title: this.L("Descripcion"),
      dataIndex: "descripcion",
      thClass: "w-auto",
      tdClass: "w-auto",
    },
    {
      title: this.L("Fecha Creacion"),
      dataIndex: "creationTime",
      scopedSlots: { customRender: "date" },
    },
    {
      scopedSlots: { customRender: "actions" },
      title: "Acciones",
      fixed: "right",
    },
  ];

  pagination: any = {};
  findModalShow: boolean = false;

  searchDefinition: {}[] = [
    {
      fieldName: "Nombre",
      fieldType: "text",
      fieldBind: "nombre",
      value: "",
    },
    {
      fieldName: "Filtro",
      fieldType: "text",
      fieldBind: "filter",
      value: "",
    },
    {
      fieldName: "Descripcion",
      fieldType: "text",
      fieldBind: "descripcion",
      value: "",
    },
  ];

  handleTableChange = async (pagination, filters, sorter) => {
    this.$store.commit("grupo/setCurrentPage", pagination.current);
    //this.getpage();

    await this.getpage();
  };

  async hide() {
    this.findModalShow = false;
  }
  async find() {
    this.findModalShow = true;
  }

  getSearch = async (model) => {
    model.forEach((element) => {
      if (element.value) {
        this.pagerequest[element.fieldBind] = element.value;
      } else {
        delete this.pagerequest[element.fieldBind];
      }
    });

    //this.pagerequest.idcampaña =;
    await this.getpage();
    this.findModalShow = false;
  };
}
